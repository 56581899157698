import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

import { db, functions } from "./firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ReactSVG } from "react-svg";

export default function Form() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [comments, setComments] = useState("");
  const [selectedOption, setSelectedOption] = useState("beta");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidUsername, setIsValidUsername] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  //const functions = getFunctions();
  //const helloWorld = httpsCallable(functions, "helloWorld");



  useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsValidEmail(emailRegex.test(email));
  }, [email]);

  useEffect(() => {
    setIsValidUsername(username.length >= 3);
  }, [username]);

  const addMember = async (email, username, selectedOption) => {
    try {
      const docRef = await addDoc(collection(db, "members"), {
        email: email,
        username: username,
        selectedOption: selectedOption,
        comments: comments,
        createdAt: serverTimestamp(),
      });
      //console.log("Member added with ID: ", docRef.id);
      setFormSubmitted(true); // Set formSubmitted state to true after successful submission
    } catch (error) {
      console.error("Error adding member: ", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidEmail && isValidUsername) {
      //console.log("Form data is valid");
      addMember(email, username, selectedOption);
    } else {
      console.log("Invalid form data");
    }
  };

  return (
    <>



<Grid sx={{mb: 6}} container spacing={2}>
  <Grid item xs={12} sm={6}>
  <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              mb: 6,
              mt: 4,
              maxWidth: "400px",
            }}
          >
            <Card sx={{ p: 2 }}>
              {formSubmitted ? (
                <>
                  <Typography variant="body1" sx={{ mt: 0 }}>
                    Hey {username}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1.5 }}>
                    Thank you for your interest in{" "}
                    <span style={{ fontWeight: 500 }}>mixr</span>.mx
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1.5 }}>
                    I'll be in touch as soon as you reach the top of the queue
                    or if I have anything significant to share.
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1.5 }}>
                    Thanks again
                    <br />
                    David
                  </Typography>
                </>
              ) : (
                // Wrap the existing form code in a React Fragment
                <>
                  <Typography
                    variant="h2"
                    sx={{
                      mb: 2.5,
                      mt: 1,
                      fontSize: "1.5rem",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Join The Waiting List
                  </Typography>
                  <Typography sx={{ mb: 2.5 }}>
                    Enter your details below and I'll email you with more
                    details at the appropriate time.
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <FormControl>
                      <TextField
                        fullWidth
                        size="small"
                        label="Email Address"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!isValidEmail && email.length > 0}
                        helperText={
                          !isValidEmail && email.length > 0
                            ? "Invalid email"
                            : ""
                        }
                        sx={{ maxWidth: "400px", mb: 2.5 }}
                      />
                      <TextField
                        fullWidth
                        size="small"
                        label="Metapop username or your name"
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        error={!isValidUsername && username.length > 0}
                        helperText={
                          !isValidUsername && username.length > 0
                            ? "Username must be at least 3 characters"
                            : ""
                        }
                        sx={{ maxWidth: "400px", mb: 3 }}
                      />
                      <RadioGroup
                        name="when"
                        sx={{ mb: 1 }}
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      >
                        <FormControlLabel
                          value="beta"
                          control={<Radio />}
                          label="I'm interested in beta testing (early access)"
                          sx={{ mb: 1 }}
                        />
                        <FormControlLabel
                          value="production"
                          control={<Radio />}
                          label="I'm happy to wait until it's formerly released"
                          sx={{ mb: 2 }}
                        />
                      </RadioGroup>

                      <TextField
                        fullWidth
                        size="small"
                        multiline
                        rows={4}
                        label="Any comments, suggestions or questions?"
                        variant="outlined"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        sx={{ maxWidth: "400px", mb: 3 }}
                      />
                      <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        disabled={!isValidEmail || !isValidUsername}
                        sx={{
                          textAlign: "center",
                          maxWidth: "400px",
                          backgroundColor: "#C23333",
                          "&:hover": {
                            backgroundColor: "#D81D1D",
                          },
                        }}
                      >
                        Join Waiting List
                      </Button>
                    </FormControl>
                  </form>
                </>
              )}
            </Card>
          </Box>
  </Grid>
  <Grid item xs={12} sm={6}>
  <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        pb:1,
        '@media (min-width: 800px)': {
          pt: 20,
        },
        height: "100%",
      }}
    >
          <img src="fadedlogo.png" alt="logo" width="300px" height="300px" />
        </Box>
      
  </Grid>
</Grid>



      
        
    </>
  );
}
