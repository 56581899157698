import * as React from "react";
import { useRef, useEffect } from "react";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Button from "@mui/material/Button";

import Form from "./Form";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { ReactSVG } from "react-svg";

import { Link, Element } from "react-scroll";
import { db } from "./firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  where,
  query,
} from "firebase/firestore";

const theme = createTheme({
  palette: {
    primary: {
      main: "#C23333",
    },
    background: {
      default: "#FBFBFB",
    },
  },
});

export default function App() {
  const waitingListRef = useRef(null);

  const scrollToWaitingList = () => {
    if (waitingListRef.current) {
      waitingListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  async function getIpAddress() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      //console.log('Your IP address is:', data.ip)
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  }

  async function logIpAddress() {
    const ipAddress = await getIpAddress();
    if (ipAddress) {
      const visitorsRef = collection(db, "visitors");
      const queryRef = query(visitorsRef, where("ipAddress", "==", ipAddress));
      const querySnapshot = await getDocs(queryRef);

      if (querySnapshot.size === 0) {
        await addMember(ipAddress);
      }
    }
  }

  const addMember = async (ipAddress) => {
    try {
      const docRef = await addDoc(collection(db, "visitors"), {
        ipAddress: ipAddress,
        createdAt: serverTimestamp(),
      });
      console.log("Member added with ID: ", docRef.id);
    } catch (error) {
      console.error("Error adding member: ", error);
    }
  };

  useEffect(() => {
    logIpAddress();
  }, []);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="md" sx={{ pt: 2 }}>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                flex: 1,
                display: "flex",

                alignItems: "center",
              }}
            >
              <ReactSVG
                afterInjection={(svg) => {
                  //console.log(svg);
                }}
                beforeInjection={(svg) => {
                  svg.classList.add("svg-class-name");
                  svg.setAttribute("style", "width: 98%");
                }}
                className="wrapper-class-name"
                desc="Description"
                evalScripts="always"
                fallback={() => <span>Error!</span>}
                httpRequestWithCredentials={true}
                loading={() => <span></span>}
                onError={(error) => {
                  console.error(error);
                }}
                renumerateIRIElements={false}
                src="logowhite4.svg"
                title="Title"
                useRequestCache={false}
                wrapper="span"
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              <Link
                to="waitingList"
                smooth={true}
                duration={500}
                spy={true}
                activeClass="active"
                offset={-50}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#C23333",
                    "&:hover": {
                      backgroundColor: "#D81D1D",
                    },
                    mb: 1,
                  }}
                >
                  Join Waitlist
                </Button>
              </Link>
            </Box>
          </Box>
          <Box sx={{ pl: 1.5, pr: 1.5 }}>
            <Typography
              variant="h1"
              sx={{
                mt: 2,
                mb: 3.5,
                fontSize: "1.6rem",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              Farewell Metapop
            </Typography>
            <Typography variant="body1" sx={{ mt: 1.5 }}>
              I've been using Metapop pretty much daily since June 2018, and am
              very sad that it is closing. It's been an amazing place for like
              minded people to share their passion for creating music. I've made
              some great friends, and have been inspired by so many talented
              people. I will miss it.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1.5 }}>
              However, I'm not giving up easily!
            </Typography>
            <Typography
              variant="h2"
              sx={{ mt: 3.5, fontSize: "1.2rem", fontWeight: 500 }}
            >
              New Platform
            </Typography>
            <Typography variant="body1" sx={{ mt: 1.5 }}>
              With the unfortunate closure of Metapop, as a software developer I
              saw an opportunity to create a platform that would provide a
              similar space for music sharing and collaboration. While my
              platform may partially fill the void left by Metapop's absence, I do not
              intend to compete with or replace the excellent services provided
              by other established communities such as Sommerklänge's Discord
              server. Rather, I aim to offer a unique and fresh approach that
              will complement existing services and provide even more
              opportunities for the music community to connect and collaborate.
            </Typography>
            <Typography
              variant="h2"
              sx={{ mt: 3.5, fontSize: "1.2rem", fontWeight: 500 }}
            >
              Features
            </Typography>

            <Typography variant="body1" sx={{ mt: 1.5 }}>
              Upload tracks, track artwork, comment on tracks, like tracks, view
              number of plays, etc. Basically the same features we had on
              Metapop.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1.5 }}>
              Organise your tracks into Playlists and Albums, and make these
              available to other artists and guests on your artist profile page.
              Metapop was great at getting artists' new music listened to, but
              left a bit to be desired when it came to browsing and listening to
              back catalogues. Spotify style playlists solve this issue. Create
              playlists featuring your own tracks, or anyone else's.
            </Typography>

            <Typography variant="body1" sx={{ mt: 1.5 }}>
              Competitions. Maybe, maybe not. There will certainly be the
              facility to have stems available for remixes, but judging
              competitions is a lot of work (how can anyone listen to 500
              tracks!?), so this requires some thought and input from the
              community on the best way to to this. The Beat Battle approach, as
              used in the most popular Metapop Group is a good one, but I'm open
              to suggestions.
            </Typography>
            <Typography
              variant="h2"
              sx={{ mt: 3.5, fontSize: "1.2rem", fontWeight: 500 }}
            >
              Running Costs
            </Typography>
            <Typography variant="body1" sx={{ mt: 1.5 }}>
              Without a doubt, this is an issue. However, there are possible
              workarounds such as:
            </Typography>

            <ul>
              <li>
                <Typography sx={{ mb: 0.75 }}>
                  Distributed content delivery (i.e. stream from multiple free
                  services)
                </Typography>
              </li>
              <li>
                <Typography sx={{ mb: 0.75 }}>
                  Distributed content storage and optimisation
                </Typography>
              </li>
              <li>
                <Typography sx={{ mb: 0.75 }}>
                  Monthly subscription for premium hosting
                </Typography>
              </li>
            </ul>

            <Typography variant="body1" sx={{ mt: 1.5 }}>
              Predicting the running costs is challenging, as it depends on the
              number of tracks streamed daily, which is difficult to estimate at
              this point. While storage costs are relatively low, content
              delivery costs can be significant, and free cloud resources for
              streaming often have fair usage policies that may result in
              disruptions to the service if exceeded.
            </Typography>
            <Typography
              variant="h2"
              sx={{ mt: 3.5, fontSize: "1.2rem", fontWeight: 500 }}
            >
              Release Date
            </Typography>
            <Typography variant="body1" sx={{ mt: 1.5 }}>
              I am planning a soft launch, gradually allowing access to small
              group of users for testing and feedback purposes. This phased
              approach will ensure the stability and reliability of the platform
              before it is fully launched without restrictions. I am aiming for
              an initial release date of late May / early June for Beta testing.
            </Typography>
            <Typography
              variant="h2"
              sx={{ mt: 3.5, fontSize: "1.2rem", fontWeight: 500 }}
            >
              Waiting List
            </Typography>
            <Typography variant="body1" sx={{ mt: 1.5 }}>
              If you're interested in this project, please do sign up to the
              waiting list. I will be sending out updates and invitations to the
              Beta test, but spaces will be limited at first, out of necessity.
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", mt: 3, mb: 5 }}>
              <Avatar
                alt="DrJkl"
                variant="square"
                sx={{ mt: 1.2, mr: 0.5, width: 60, height: 60 }}
                src="drjkl.png"
              />
              <Box sx={{ ml: 1 }}>
                <Typography variant="body1" sx={{ mt: 1.5 }}>
                  Thanks for reading
                </Typography>
                <Typography variant="body1" sx={{ mt: 0.5, fontWeight: 500 }}>
                  David Macfarlane (aka DrJkl)
                </Typography>
              </Box>
            </Box>
            <Element name="waitingList">
              <Form />
            </Element>
          </Box>
        </Container>
        <Box
          sx={{
            backgroundColor: "#1F2020",
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            pr: 3,
          }}
        >
          <img
            src="reigniteyoursound.png"
            alt="Reignite Your Sound"
            width="180px"
          />
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
}
