// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, getDoc, query, getDocs, collection, where, addDoc, doc, setDoc } from "firebase/firestore"
import { getFunctions, httpsCallable } from "firebase/functions"; // Add this import


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDmAum5-UmpC-cjrRrRllJLHq1AemnAkW0",
  authDomain: "mixr-mx.firebaseapp.com",
  projectId: "mixr-mx",
  storageBucket: "mixr-mx.appspot.com",
  messagingSenderId: "614376661860",
  appId: "1:614376661860:web:da44488dfc6b2533629071",
  measurementId: "G-RTMY65CRP7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app); // Add this line


export { db, functions, httpsCallable }; 